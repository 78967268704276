import React from 'react'
import {  FaTelegramPlane, FaTwitter } from 'react-icons/fa';

const Footer = (props) => {
 
  return (
    <div className= { props.CurrentAccount ? 'footermove' :'fdontmove'} >
       <h2 > 
          <a href="https://t.me/StonksPortal" className='icon' target="_blank" rel="noopener noreferrer"><FaTelegramPlane /></a>&nbsp;&nbsp;&nbsp;
          <a href="https://twitter.com/StonksToken" className='icon' target="_blank" rel="noopener noreferrer"><FaTwitter /> </a>
       </h2>
    </div>
  )
}

export default Footer   