import React from 'react'


const About = () => {
  return (
    <div className="aboutsection" id={'whatisthis'}>
       <h5>About</h5>
       <p>Welcome to our platform, where we offer binary options, a derivative with clear-cut outcomes of either a win or loss. Users can earn rewards of up to 80% or 95% of their stakes based on volatility and expiration, but with a maximum reward cap of 100%. To break even, a win rate of over 55% is required. Note that rewards decrease by 1% per hour, and the percentage of volatility affects the reward percentage, with a range of 100% to 80%. Our options don't close automatically, so you can close them at any point to reap maximum rewards. Join us now to start trading with confidence.</p>
    </div>
  )
}

export default About 